import { VuexModule, Module, Action, Mutation } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { Dialog, FilterProject, Unselect } from './types';
import { savedSearchModule } from '../savedSearch';
import { Project } from '@/interfaces/project';

@Module({ generateMutationSetters: true })
class ProjectModule extends VuexModule {
    project: any = {
        data: undefined,
        loading: false
    };

    endpoints = {
        byId: 'project/get-project-by-id/',
        project: 'project/get-data-from-filter-v2',
        models: 'project/get-typologies-by-id-project/'
    };

    discarded: { data?: any[]; total: number } = {
        data: undefined,
        total: 0
    };

    projectsFromBuilder: any[] = [];

    dialogs: Dialog = {
        filter: false,
        orderBy: false,
        map: false,
        sendEmail: false,
        cleanFilters: false
    };

    unselect: Unselect = {
        projectName: true,
        address: true,
        sendWsp: true,
        sendEmail: true
    };

    projects: {
        data: Project[];
        total?: number;
        loading: boolean;
        maximumLimit?: boolean;
        notResetScroll?: boolean;
    } = {
        data: [],
        loading: false
    };

    selectProjects = false;
    view: 'list' | 'map' | 'detail' = 'detail';

    searchFilters: Partial<FilterProject> = {};

    filter: FilterProject = {
        price: [],
        bedrooms: [],
        bathrooms: [],
        meters: [],
        projectTypes: [1, 2],
        typologyTypes: [],
        projectPhases: [],
        financeBanks: [],
        orientation: [],
        orderBy: 'precio_menor',
        page: 1,
        discarded: this.discardedLocal(),
        locations: [],
        departamento: [],
        distrito: [],
        provincia: [],
        urbanization_id: [],
        only: [],
        condition: [],
        commission: []
    };

    order = [
        {
            value: 'precio_mayor',
            label: 'Mayor precio'
        },
        {
            value: 'precio_menor',
            label: 'Menor precio'
        },
        {
            value: 'area_mayor',
            label: 'Mayor área'
        },
        {
            value: 'area_menor',
            label: 'Menor área'
        },
        {
            value: 'mas_reciente',
            label: 'Más recientes'
        },
        {
            value: 'mas_antiguo',
            label: 'Más antiguos'
        }
    ];

    get emptyState() {
        return !this.projects.loading && this.projects.data.length < 1;
    }

    @Action
    async setSearchFilters(object: Partial<FilterProject>) {
        this.searchFilters = object;
    }

    @Action
    async setUnselect(object: Partial<Unselect>) {
        this.unselect = Object.assign(this.unselect, object);
    }

    @Action
    async setEndpoint(object: any) {
        this.endpoints = Object.assign(this.endpoints, object);
    }

    @Action
    async setProjectsFromBuilder(projects: any[]) {
        this.projectsFromBuilder = projects;
    }

    @Action
    async getDiscarded(filter: any = {}) {
        if (this.filter.discarded && this.filter.discarded.length > 0) {
            if (
                (filter.discarded && filter.discarded.length < 1) ||
                !filter.discarded
            ) {
                filter.discarded = this.filter.discarded;
            }
            const { data } = await Axios.get(
                'project/get-data-from-filter-v2',
                {
                    params: {
                        type: 'descartar',
                        ...filter
                    }
                }
            );
            this.discarded = data;
        }
        if (this.filter.discarded && this.filter.discarded.length < 1) {
            this.discarded = { data: undefined, total: 0 };
        }
    }

    @Action
    async getProjectsFromBuilder(id: number) {
        const { data } = await Axios.get(
            `project/get-projects-from-builder/${id}`,
            {
                params: { discarded: this.filter.discarded }
            }
        );
        this.projectsFromBuilder = data;
    }

    @Mutation
    projectsLoading(notResetScroll = false) {
        this.projects.notResetScroll = notResetScroll;
        this.projects.loading = true;
    }

    @Action
    async changeView(value: 'list' | 'map' | 'detail') {
        this.view = value;
    }

    @Action
    async setFilter(data: Partial<FilterProject>) {
        this.filter = { ...this.filter, ...data, page: 1 };
        if (this.filter.projectTypes && this.filter.projectTypes.length < 1) {
            this.filter.projectTypes = [1, 2];
            this.filter = Object.assign(this.filter, this.filter);
        }
        this.getProjects();
    }

    @Action
    async getLocations(query: string) {
        const { data } = await Axios.get(`/ubigeo/all?texto=${query}`, {
            params: { ignoreLoading: true }
        });
        return data;
    }

    @Action
    async getContactProject(query: string) {
        const { data } = await Axios.get(
            `project/get-contact-project/${query}`
        );
        return data;
    }

    @Action
    async closeOrOpenDialog(key: keyof Dialog) {
        this.dialogs = Object.assign(this.dialogs, {
            [key]: !this.dialogs[key]
        });
    }

    @Action
    async updateOne(payload: Pick<Project, '_id' | 'condition_pago'>) {
        this.projects = {
            ...this.projects,
            data: this.projects.data.map(project => {
                if (project._id === payload._id) {
                    return { ...project, ...payload };
                }
                return project;
            })
        };
    }

    @Action
    async updateTypology(payload: {
        _id: string;
        comisionTipologia: number;
        mongo_projectId: string;
    }) {
        this.projects = {
            ...this.projects,
            data: this.projects.data.map(project => {
                if (
                    project._id === payload.mongo_projectId &&
                    project.typologies?.data
                ) {
                    return {
                        ...project,
                        typologies: {
                            ...project.typologies,
                            data: project.typologies.data.map(typology => {
                                if (
                                    typology._id === payload._id
                                ) {
                                    return {
                                        ...typology,
                                        comisionTipologia:
                                            payload.comisionTipologia
                                    };
                                }
                                return typology;
                            })
                        }
                    };
                }
                return project;
            })
        };
    }

    @Action
    async getProjects(
        body: {
            query?: any;
            idSavedSearch?: string;
            requestInitial?: boolean;
        } = {}
    ) {
        if (body && body.query && !body.idSavedSearch) {
            await this.setFilterByQuery(body.query);
        }
        this.projectsLoading();
        let response = {
            data: [],
            total: 0,
            loading: false,
            maximumLimit: false
        };
        try {
            if (body.idSavedSearch) {
                await this.getSavedSearch(body.idSavedSearch);
                this.view = body.query ? body.query.view || 'detail' : 'detail';
            } else if (body.requestInitial && !body.idSavedSearch) {
                this.filter = Object.assign(this.filter, { page: 1 });
                savedSearchModule.selectSavedSearch();
            }
            const { data } = await Axios.get(this.endpoints.project, {
                params: {
                    ...this.filter,
                    only: this.selectProjects ? [] : this.filter.only
                }
            });

            response = {
                total: data.total,
                data: data.data,
                maximumLimit: data.data.length === data.total,
                loading: false
            };
        } catch (err) {
            console.log(err);
        }
        this.projects = response;
    }

    @Action
    async findTypologiesByProjectId(projectId: number) {
        const project = this.projects.data.find(
            ({ project_id }) => project_id === projectId
        );
        if (project) {
            const pagination = project.typologies || {
                page: 1,
                total: 0,
                data: []
            };
            const { data: response } = await Axios.get(
                `${this.endpoints.models}${projectId}`,
                {
                    params: { ...this.filter, page: pagination.page }
                }
            );
            project.typologies = {
                total: response.total,
                data: response.data.concat(pagination.data),
                page: response.page + 1
            };

            this.projects = {
                ...this.projects,
                data: this.projects.data.map(project => {
                    if (project.project_id === projectId) {
                        return { ...project };
                    }
                    return project;
                })
            };
        }
    }

    @Action
    async getProjectsByScroll(page: number) {
        this.projectsLoading(true);

        this.filter = { ...this.filter, page };

        const { data } = await Axios.get(this.endpoints.project, {
            params: { ...this.filter, only: [], page }
        });

        const projects = this.projects.data.concat(data.data);
        this.projects = {
            total: data.total,
            data: this.projects.data.concat(data.data),
            maximumLimit: projects.length === data.total,
            loading: false
        };
    }

    @Action
    async getSavedSearch(id?: string) {
        if (!savedSearchModule.savedSearchSelected && id) {
            const { data } = await Axios.get(`busqueda/${id}`);
            if (data) {
                this.filter = {...data.filter, only: [] };
                await savedSearchModule.selectSavedSearch(data);
            }
            return data;
        }
        return savedSearchModule.savedSearchSelected;
    }

    @Action
    async setFilterByQuery(query?: any) {
        if (query) {
            const filter = Object.keys(query).reduce((obj: any, key) => {
                if (Array.isArray(this.filter[key as keyof FilterProject])) {
                    if (Array.isArray(query[key])) {
                        obj[key] = query[key].map((element: string) => {
                            const parse = element.match(/^[0-9a-fA-F]{24}$/)
                                ? element
                                : parseFloat(element);
                            return parse || element;
                        });
                    } else if (!query[key].match(/^[0-9a-fA-F]{24}$/)) {
                        const parse = parseFloat(query[key]);
                        obj[key] = [parse || query[key]];
                    } else {
                        obj[key] = [query[key]];
                    }
                } else {
                    const parse = parseFloat(query[key]);
                    obj[key] = parse || query[key];
                }
                return obj;
            }, {});
            this.view = query.view || this.view;
            this.filter = Object.assign(this.filter, filter);
        }
    }

    @Action
    async getProject(options: { id: number; idProject?: string; query?: any }) {
        this.project = { loading: true, data: undefined };
        const savedSearch = await this.getSavedSearch(options.idProject);
        await this.setFilterByQuery(options.query);

        if ((savedSearch && options.idProject) || !options.idProject) {
            const { data } = await Axios.get(
                `${this.endpoints.byId}${options.id}`,
                {
                    params: {
                        ...this.filter,
                        only: [],
                        page: 1,
                        all: true,
                        projectId: options.id
                    }
                }
            );
            this.project = { data, loading: false };
        }
    }

    @Action
    async setProject(data: any) {
        this.project = this.project.data
            ? { data: { ...this.project.data, ...data }, loading: false }
            : {
                  data,
                  loading: false
              };
    }

    @Action
    async discardProject(id: string) {
        if (this.filter.discarded) {
            this.filter.discarded.push(id);
        }

        if (!savedSearchModule.savedSearchSelected) {
            localStorage.setItem(
                'discarded',
                JSON.stringify(this.filter.discarded)
            );
        } else {
            savedSearchModule.editSearch({ discarded: this.filter.discarded });
        }

        this.setFilter({
            discarded: this.filter.discarded
        });
    }

    @Action
    async addProject(id: string) {
        if (this.filter.discarded) {
            const discarded = this.filter.discarded.filter(_id => _id !== id);
            this.filter = Object.assign(this.filter, { discarded });
            if (!savedSearchModule.savedSearchSelected) {
                localStorage.setItem('discarded', JSON.stringify(discarded));
            } else {
                savedSearchModule.editSearch(this.filter);
            }
            this.getDiscarded();
        }
    }

    @Action
    async upsertProjects(id: string) {
        if (this.filter.only) {
            const index = this.filter.only.findIndex(x => x === id);
            if (index >= 0) {
                this.filter.only.splice(index, 1);
            } else {
                this.filter.only.push(id);
            }
        }
    }

    @Action
    async clearSelectedProjects() {
        this.filter.only = [];
    }

    @Action
    async activeOrDisabledChecked(value?: boolean) {
        this.selectProjects =
            value !== undefined ? value : !this.selectProjects;
    }

    @Action
    async sendEmail(body: any) {
        const { data } = await Axios.post('email-busqueda', {
            ...body,
            filter: { ...this.filter, page: 1, discarded: [] }
        });
        return data;
    }

    private discardedLocal(): string[] {
        let value: string | null | string[] = localStorage.getItem('discarded');
        if (!value) return [];

        try {
            value = JSON.parse(value);
        } catch {
            return [];
        }

        return value as string[];
    }
}

export const projectModule = new ProjectModule({ store, name: 'project' });
